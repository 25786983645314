import { Box, Container, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import SponsorLogos from "./sponsorLogos"

const AboutSlideFour = () => {
  return (
    <SectionWrapper>
      <Typography variant="body2">
        We are a group of <strong>15 leaders</strong> from civil society,
        governments, private sector, philanthropy and inter-governmental
        agencies committed to{" "}
        <strong>making technology and innovation gender equal</strong> through
        implementing an ambitious five years plan and mobilizing other partners
        to <strong>join us in this commitment</strong>.
      </Typography>

      <Container maxWidth="sm" disableGutters>
        <Box position="relative" mt={4} pb={`${(9 / 16) * 100}%`} height={0}>
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/NjpV1ILuyck?rel=0&modestbranding=1"
            title="YouTube video player"
            frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Container>

      <Box mt={5}>
        <SponsorLogos />
      </Box>
    </SectionWrapper>
  )
}

export default AboutSlideFour