import { Box, Typography } from "@mui/material"
import { grey } from '@mui/material/colors';
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"


const SponsorLogos = () => {
  const {
    allFile: { nodes: logos },
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "sponsors" } }) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, width: 300)
            }
          }
        }
      }
    `
  )

  const LogoImage = ( {filename, width, href, label, border}) => {
    const myLogo = logos.find(element => element.name === filename)
    return (
      <Box
        width={width ? width : 100}
        m={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <a href={href ? href : undefined} target="_blank" rel="noreferrer">
          <GatsbyImage
            image={getImage(myLogo)}
            alt={filename}
            style={{ border: border ? `1px solid ${grey[400]}` : "none" }}
          />
        </a>
        {label && (
          <Typography
            align="center"
            style={{
              fontFamily: "Work Sans",
              fontSize: "0.875rem",
              fontWeight: 500,
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
    )
    
  }

  const borderStyle = `2px solid ${grey[400]}`

  return (
    <>
      <Box display="flex" pt={5} style={{ borderBottom: borderStyle }}>
        <Box
          display="flex"
          // flexWrap="wrap"
          flexDirection="column"
          width="50%"
          justifyContent="space-between"
          padding={2}
          alignItems="center"
          style={{ borderRight: borderStyle }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            width={1}
            pb={2}
          >
            <LogoImage
              filename="armenia"
              href="https://www.gov.am/en/"
              label="Armenia"
              width={80}
              border
            />
            <LogoImage
              filename="chile"
              href="https://www.gob.cl/en/"
              label="Chile"
              width={80}
              border
            />
            <LogoImage
              filename="tunisia"
              href="http://www.pm.gov.tn/pm/content/index.php?lang=en"
              label="Tunisia"
              width={80}
              border
            />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            width={1}
          >
            <LogoImage
              filename="rwanda"
              href="https://www.gov.rw"
              label="Rwanda"
              width={80}
              border
            />
            <LogoImage
              filename="finland"
              href="https://um.fi/equaltech"
              label="Finland"
              width={80}
              border
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="50%"
          justifyContent="space-between"
          padding={3}
          alignItems="center"
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            width={1}
          >
            <LogoImage
              filename="rockefeller"
              href="https://www.rockefellerfoundation.org/"
              width={150}
            />
            <LogoImage
              filename="koc"
              width={100}
              href="https://www.koc.com.tr/en"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            width={1}
          >
            <LogoImage
              filename="microsoft"
              width={150}
              href="https://www.microsoft.com/en-us/about/"
            />
            <LogoImage
              filename="salesforce"
              href="https://www.salesforce.com/company/philanthropy/overview/"
              width={110}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          width={3/10}
          justifyContent="space-around"
          style={{ borderRight: borderStyle }}
          padding={3}
          alignItems="center"
        >
          <LogoImage
            filename="digital-grassroots"
            href="https://digitalgrassroots.org/"
            width={100}
          />
        </Box>
        <Box
          width={6/10}
          padding={3}
          style={{ borderRight: borderStyle }}
          alignItems="center"
        >
          <Box display="flex" justifyContent="center">
            <LogoImage
              filename="gfw"
              href="https://www.globalfundforwomen.org/reclaiming-tech-with-united-nations-generation-equality-forum/"
              width={120}
            />
          </Box>
          <Box display="flex" justifyContent="space-around">
            <LogoImage
              filename="socialbuilder"
              href="https://socialbuilder.org/00-english/"
              width={120}
            />
            <LogoImage
              filename="aplusalliance"
              href="https://www.womenatthetable.net/"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          width={3/10}
          justifyContent="space-around"
          padding={3}
          alignItems="center"
        >
          <LogoImage
            filename="unicef"
            href="https://www.unicef.org/"
            width={80}
          />
          <LogoImage filename="itu" href="https://www.itu.int" width={60} />
        </Box>
      </Box>
    </>
  )
}

export default SponsorLogos
