import { green, blue, deepPurple, orange } from "@mui/material/colors"

const aboutBlocksData = [
  {
    verb: "closing",
    object: "gender digital gap",
    color: green[800],
    popupContent: {
      title: "Bridge the Gender Gap in Digital Access and Competences",
      goal:
        "By 2026, reduce by half the gender digital divide across generations by accelerating meaningful access to digital technologies and universal digital literacy.",
      tactics: [
        {
          header: "financing",
          detail: "financing 21st century skills",
          actions: [
            "Create safe environments to access digital technology,",
            "Shape blended financing instruments,",
            "Provide scholarship opportunities",
          ],
        },
        {
          header: "service delivery",
          detail: "making digital tools accessible",
          actions: [
            "Develop gender transformative distance and blended learning",
            "Improve affordability of digital devices and data plans",
          ],
        },
        {
          header: "social norms",
          detail: "addressing social norms",
          actions: [
            "Develop gender transformative teaching",
            "Engage caregivers to prevent bias",
            "Eliminate stereotypes and practices limiting access",
          ],
        },
      ],
    },
  },
  {
    verb: "making",
    object: "technology feminist",
    color: blue[800],
    popupContent: {
      title: "Invest in Feminist Technology and Innovation",
      goal:
        "By 2026, increase investments towards feminist technology and innovation by 50% to support women’s leadership as innovators and better respond to women and girls’ most pressing needs.",
      tactics: [
        {
          header: "service delivery",
          detail: "embed gender in tech-development",
          actions: [
            "Amplify mission driven, grassroots and systemic innovations",
            "Making tech accessible, usable and sustainable for all women and girls",
          ],
        },
        {
          header: "laws & policies",
          detail: "adopt feminist tech-development policies",
          actions: [
            "Adopt policies mitigating risks, mainstreaming  gender by design and fostering knowledge exchange",
          ],
        },
        {
          header: "data & accountability",
          detail: "make data analytics inclusive and accountable",
          actions: [
            "Systematize gender impact assessments, AI audits, high quality data for evidence-based solutions",
          ],
        },
      ],
    },
  },
  {
    verb: "building",
    object: "transformative ecosystems",
    color: deepPurple[800],
    popupContent: {
      title:
        "Build Inclusive, Transformative and Accountable Innovation Ecosystems",
      goal:
        "By 2026, double the proportion of women working in technology and innovation by setting up new networks and benchmarks to transform innovation ecosystems.",
      tactics: [
        {
          header: "service delivery",
          detail: "create gender-transformative hubs",
          actions: [
            "Finance local and regional feminist networks within digital/ innovation hubs",
            "Facilitate access to role models and mentors",
          ],
        },
        {
          header: "laws & policies",
          detail: "policies to boost women and girls’ leadership",
          actions: [
            "Mainstream gender in digital public policies,",
            "Commit to parity in workforce,",
            "Increase women’s participation in digital cooperation",
          ],
        },
        {
          header: "data & accountability",
          detail: "transform metrics to account for diversity",
          actions: [
            "Define indicators to measure the gender digital divide and gendered impact of digital transformation",
          ],
        },
      ],
    },
  },
  {
    verb: "leaving",
    object: "no space for online violence",
    color: orange[800],
    popupContent: {
      title:
        "Prevent and Eliminate Online and Tech-Facilitated GBV and Discrimination",
      goal:
        "By 2026, a majority of countries and tech companies demonstrate accountability by implementing policies and solutions against online and tech facilitated GBV and discrimination.",
      tactics: [
        {
          header: "service delivery",
          detail: "gender-sensitive design of GBV monitoring tools",
          actions: [
            "Publicly share reports on spread of online GBV",
            "Improve tools that avoid harm as well as improve user care services",
          ],
        },
        {
          header: "laws & policies",
          detail: "shaping a restorative justice response mechanism",
          actions: [
            "Adopt adequate legislation, train and fund law enforcement,",
            "Fund support to victims and monitoring tools",
          ],
        },
        {
          header: "social norms",
          detail: "demonstrate online cultural change",
          actions: [
            "Organize global campaigns,",
            "Sensitize young people, care-givers and educators to protect from, prevent, and handle online GBV",
          ],
        },
      ],
    },
  },
]

export default aboutBlocksData