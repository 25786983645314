import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React, { useState } from "react"
import SectionWrapper from "../sectionWrapper"
import blocksData from "./aboutBlocksData"


// const useStyles = makeStyles(theme => ({
//   cardRoot: {
//     backgroundColor: theme.palette.background.default,
//   },
//   cardActionArea: {
//     minHeight: 200,
//   },
// }))

const PopupBox = ({ verb, object, color, popupContent, index }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Card>
        <CardActionArea
          onClick={() => setDialogOpen(true)}
          sx={{ minHeight: 200 }}
        >
          <CardContent style={{ textAlign: "center" }}>
            <Typography variant="h5">{verb}</Typography>
            <Typography variant="h5" style={{ color: color }}>
              {object}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {popupContent.title}
            <IconButton onClick={() => setDialogOpen(false)} edge="end">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 24 }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>goal</TableCell>
                  <TableCell>tactics</TableCell>
                  <TableCell>to make it happen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popupContent.tactics.map(
                  ({ header, detail, actions }, index) => (
                    <TableRow key={header}>
                      {index === 0 && (
                        <TableCell
                          rowSpan={popupContent.tactics.length}
                          style={{ maxWidth: 300 }}
                        >
                          {popupContent.goal}
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography
                          variant="h6"
                          style={{ fontFamily: "Roboto" }}
                        >
                          {header}
                        </Typography>
                        <Typography>{detail}</Typography>
                      </TableCell>
                      <TableCell style={{ maxWidth: 600 }}>
                        <ul
                          style={{
                            paddingLeft: 20,
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          {actions.map((action, index) => (
                            <li key={index}>{action}</li>
                          ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}

const AboutSlideThree = () => {
  return (
    <SectionWrapper light>
      <Typography variant="body2">
        Our coalition explores how technology and innovation can help advance
        gender equality and create new solutions responding to women and girls
        needs in all their diversity. We are making this happen through four{" "}
        <strong>catalytic, scalable and measurable</strong> actions:
      </Typography>

      <Box pt={5}>
        <Grid container>
          {blocksData.map(({ verb, object, color, popupContent }, index) => (
            <Grid key={verb} item xs={12} md={3}>
              <PopupBox
                verb={verb}
                object={object}
                color={color}
                popupContent={popupContent}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </SectionWrapper>
  )
}

export default AboutSlideThree