import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const boxesData = [
  {
    title: "Multistakeholder",
    detail:
      "The Action Coalition brings diverse champions together—governments, the UN system, private sector, philanthropies, and feminist, women’s rights and youth organizations",
  },
  {
    title: "Co-created",
    detail:
      "The Action Coalition seeks to build a collective vision by centering on dialogue and shared perspective. Champions have been working together to co-design Blueprints for action that articulate an ambitious agenda that will accelerate progress towards gender equality and the SDGs.",
  },
  {
    title: "Impact-oriented",
    detail:
      "The Action Coalition seeks to achieve significant change and remove the most intractable barriers to equality. Champions are committing for five years to lead to lasting results and the transformation of structures, systems and power.",
  },
]

const AboutSlideTwo = () => {
  return (
    <SectionWrapper>
      <Typography variant="body2">
        The Action Coalition on Technology and Innovation is one of the Six
        Action Coalitions as part of the Generation Equality Forum. These Action
        Coalitions are the world’s roadmap for gender equality. They are
        innovative, multi-stakeholder partnerships  focused on the most critical
        areas in gender equality to achieve concrete change for women and girls
        worldwide.
      </Typography>

      <Box pt={5}>
        <Grid container>
          {boxesData.map(({ title, detail }) => (
            <Grid item xs={12} md={4} key={title}>
              <Card style={{height: "100%"}}>
                <CardHeader title={title} />
                <CardContent>{detail}</CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </SectionWrapper>
  )
}

export default AboutSlideTwo