import React from "react"
import SectionWrapper from "../sectionWrapper"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const updatesData = [
  {
    title: "Gender-Diverse Digital Reset",
    overline: "Statement",
    detail:
      "Action Coalition leaders’ statement on a GENDER-DIVERSE DIGITAL RESET at Davos Summit 2020",
    buttons: [
      {
        label: "Read more",
        link:
          "https://forum.generationequality.org/sites/default/files/2021-01/WEF_Davos%20AC%20T%26I%20Leaders%20Statement%20FINAL.pdf",
      },
    ],
  },
  {
    title: "GEF Mexico City",
    overline: "Event",
    detail:
      "Action Coalition on Technology and Innovation for Gender Equality launched in March 2021",
    buttons: [
      {
        label: "Watch session 1",
        link: "https://www.youtube.com/watch?v=R0md6zOJew8",
      },
      {
        label: "Watch session 2",
        link: "https://www.youtube.com/watch?v=JB3ySvcwy30",
      },
    ],
  },
  {
    title: "Youth Participation",
    overline: "Dialogue",
    detail:
      "The first youth roundtable on Technology and Innovation for Gender Equality hosted by Digital Grassroots",
    buttons: [
      {
        label: "Read more",
        link:
          "https://forum.generationequality.org/sites/default/files/2021-02/Youth%20Roundtable%20-%20AC%20T%26I%20-%20Outcome%20document%20%281%29.pdf",
      },
    ],
  },
]

const CoalitionUpdates = () => {
  return (
    <SectionWrapper>
      <Typography variant="h2" align="center">
        Coalition Updates
      </Typography>
      <Grid container>
        {updatesData.map(({ title, overline, detail, buttons }) => (
          <Grid item xs={12} md={4} key={title}>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div>
                <CardHeader title={title} subheader={overline} />
                <CardContent>
                  <Typography>{detail}</Typography>
                </CardContent>
              </div>
              <CardActions>
                <Box>
                  {buttons.map(({ label, link }, index) => (
                    <div key={index}>
                      <Button
                        href={link}
                        endIcon={<ArrowForwardIcon />}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </Button>
                    </div>
                  ))}
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default CoalitionUpdates