import React from "react"
import AboutSlideFive from "../components/aboutSlides/aboutSlideFive"
import AboutSlideFour from "../components/aboutSlides/aboutSlideFour"
import AboutSlideOne from "../components/aboutSlides/aboutSlideOne"
import AboutSlideThree from "../components/aboutSlides/aboutSlideThree"
import AboutSlideTwo from "../components/aboutSlides/aboutSlideTwo"
import CoalitionUpdates from "../components/aboutSlides/coalitionUpdates"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"

const AboutPage = ({ pageContext: { originalPath } }) => {
  const { t } = useTranslation()

  return (
    <Layout title={t("about")} originalPath={originalPath}>
      <Seo title={t("about")} />
      <AboutSlideOne />
      <AboutSlideTwo />
      <AboutSlideThree />
      <AboutSlideFour />
      <AboutSlideFive />
      <CoalitionUpdates />
    </Layout>
  )
}

export default AboutPage