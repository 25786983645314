import { Box, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const Highlight = ({ children }) => (
  <Typography variant="h2" component="span" color="primary">
    {children}
  </Typography>
)

const AboutSlideOne = () => {
  return (
    <SectionWrapper light>
      <Typography variant="h2" component="p">
        25 years since the World Conference on Women in Beijing, the world has
        witnessed two things: <Highlight>a global digital revolution</Highlight>{" "}
        and{" "}
        <Highlight>
          not a single country having achieved gender equality.
        </Highlight>
      </Typography>
      <Box pt={8}>
        <Typography variant="h4" component="p">
          The time for action is NOW! to leverage technology and innovation for
          gender equality
        </Typography>
      </Box>
    </SectionWrapper>
  )
}

export default AboutSlideOne