import { Box, Button, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const AboutSlideFive = () => {
  return (
    <SectionWrapper light>
      <Typography variant="body2">
        Together, we call for collective responsibility,  especially from
        governments and corporations, to develop bold gender- transformative
        actions to widen innovation ecosystems, embed transparency and
        accountability in digital technology, and expand inclusive digital
        economies. 
      </Typography>
      <Box pt={5}>
        <Typography>
          Find out more in the{" "}
          <Button
            href="https://forum.generationequality.org/sites/default/files/2021-06/UNW%20-%20GAP%20Report%20-%20EN.pdf"
            endIcon={<ArrowForwardIcon />}
            target="_blank"
            rel="noreferrer"
          >
            Global Acceleration Plan for Gender Equality
          </Button>
        </Typography>
      </Box>
    </SectionWrapper>
  )
}

export default AboutSlideFive